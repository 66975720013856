.Container {
  width: 560px;
  min-height: 380px;
  display: flex;
  flex-direction: column;
}
.Header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Title {
  @apply text-xl;
  color: #1a1a1a;
  font-weight: 500;
}

.Description {
  @apply text-sm;
  color: var(--dop-color-text-level3, #999);
}

.Search {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.MemberList {
  display: flex;
  min-height: 72px;
  max-height: 230px;
  overflow-y: scroll;
  padding: var(--Space-Medium, 12px) 0px 12px 0px;
  flex-direction: column;

  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed var(--color-border-level3, #d8d8d8);
}

.Member {
  display: flex;
  padding: 0px var(--Space-Small, 8px) 0px 16px;
  align-items: center;
  align-self: stretch;
  gap: 8px;
}

.ErrorMessage {
  @apply text-sm;
  flex: 1;
  color: var(--color-text-caution, #e84b4b);
}

.Footer {
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
}
