.Container {
  padding: 40px 72px;
}

.Card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid #d8d8d8;
}

.CardTitle {
  color: var(--color-text-level1, #363636);

  font-size: var(--font-size-XLarge, 20px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-multi-line-height-XLarge, 30px); /* 150% */
  letter-spacing: var(--font-letter-spacing-XLarge, -0.4px);
}

.Contents {
  display: flex;
  flex-direction: column;
  gap: var(--Space-Large, 16px);
}

.Header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.Dialog {
  width: 800px;
  height: 800px;
}

.IconButton {
  height: 32px;
  display: flex;
  align-items: center;
}

.Divider {
  height: 12px;
  width: 1px;
  background-color: var(--color-border-level3, #d8d8d8);
}

.Select {
  width: 43px;
  height: 28px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
