.Container {
  padding: 40px 72px;
}

.Card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid #d8d8d8;
}

.Header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.HeaderTitle {
  @apply text-xl;
  color: var(--Gray-800, #333);
  font-weight: 600;
}

.Description {
  @apply text-sm;
  color: #888;
}

.Contents {
  display: flex;
  padding: 12px 0;
}

.FieldTitle {
  @apply text-sm;
  width: 240px;
  min-width: 240px;
  overflow: hidden;
  color: var(--dop-color-text-level1, #363636);
  text-overflow: ellipsis;
}

.OperatorList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 12px;
  gap: 6px;
  border-radius: var(--Radius-XS, 4px);
  border: 1px dashed var(--dop-color-border-level3, #d8d8d8);
}

.PlusButton {
  @apply text-sm;
  color: var(--dop-color-text-level1, #363636);
  text-align: center;
}

.AddButton {
  width: 84px !important;
  height: 36px;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--dop-color-border-button-level2, #c5c5c5);
  background: var(--dop-color-bg-base, #fff);
}

.Buttons {
  display: flex;
  gap: 8px;
  padding: 16px 0;
  justify-content: center;
}
